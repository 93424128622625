import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {motion} from 'framer-motion';
import {useSearchParams} from 'react-router-dom';
import {isDesktop} from 'react-device-detect';
import {toast} from 'react-toastify';

import {PostApiV1UsersResetPasswordBody, usePostApiV1UsersResetPassword} from 'api/generated';
import {DEEP_LINK_SCHEME} from 'config';
import {extractError} from 'utils';
import {useTitle} from 'hooks';
import {Button, InputText} from 'components';

type FormSchemaType = Omit<PostApiV1UsersResetPasswordBody, 'token'>;

const schema: yup.Schema<FormSchemaType> = yup.object().shape({
  password: yup.string().required('Password cannot be blank'),
  passwordConfirmation: yup.string().required('Password cannot be blank'),
});

interface IResetPasswordProps {}

const ResetPassword: React.FC<IResetPasswordProps> = () => {
  useTitle('Reset password');

  const [params] = useSearchParams();
  const token = params.get('token');

  const {
    control,
    handleSubmit: formSubmit,
    formState: {errors, isValid},
  } = useForm<FormSchemaType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const resetPassword = usePostApiV1UsersResetPassword();
  const [passwordReset, setPasswordReset] = useState<boolean>(false);

  const handleSubmit = async (data: FormSchemaType) => {
    if (!token) return;
    const {data: res} = await resetPassword.mutateAsync({
      data: {token, password: data.password, passwordConfirmation: data.passwordConfirmation},
    });
    if (res.data) {
      setPasswordReset(true);
    } else {
      setPasswordReset(false);
      toast.error(extractError(res.errors));
    }
  };

  useEffect(() => {
    if (!isDesktop) {
      const deepLinkUrl = `${DEEP_LINK_SCHEME}://reset-password?token=${token}`;
      window.location.replace(deepLinkUrl);
    }
  }, [token]);

  if (!isDesktop) {
    return (
      <div className="flex justify-center py-10">
        <p>Install the app to reset password.</p>
      </div>
    );
  }

  return (
    <div className="flex justify-center py-10">
      {(() => {
        if (!token) {
          return (
            <motion.div
              className="bg-white p-10 shadow-lg rounded-xl flex flex-col justify-center items-center"
              initial={{opacity: 0, translateY: 40}}
              animate={{opacity: 1, translateY: 0}}>
              <p className="text-3xl text-red-400 mb-4">Error.</p>
              <p>Failed to reset password.</p>
            </motion.div>
          );
        }

        if (!passwordReset) {
          return (
            <form onSubmit={formSubmit(handleSubmit)}>
              <motion.div
                className="bg-white p-10 shadow-lg rounded-xl flex flex-col justify-center items-center"
                initial={{opacity: 0, translateY: 40}}
                animate={{opacity: 1, translateY: 0}}>
                <p className="text-3xl text-gray-800 font-semibold mb-8">Reset your password.</p>
                <Controller
                  control={control}
                  name="password"
                  render={({field: {value, onChange}}) => {
                    return (
                      <InputText
                        label="Password"
                        type="password"
                        value={value}
                        onChange={onChange}
                        error={errors.password?.message}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="passwordConfirmation"
                  render={({field: {value, onChange}}) => {
                    return (
                      <InputText
                        label="Password Confirmation"
                        type="password"
                        value={value}
                        onChange={onChange}
                        error={errors.passwordConfirmation?.message}
                      />
                    );
                  }}
                />
                <Button text="Reset" disabled={!isValid} loading={resetPassword.isLoading} />
              </motion.div>
            </form>
          );
        }

        return (
          <motion.div
            className="bg-white p-10 shadow-lg rounded-xl flex flex-col justify-center items-center"
            initial={{opacity: 0, translateY: 40}}
            animate={{opacity: 1, translateY: 0}}>
            <p className="text-3xl text-green-500 mb-4">Password is reset.</p>
            <p>Please return to the app.</p>
          </motion.div>
        );
      })()}
    </div>
  );
};

export default ResetPassword;
