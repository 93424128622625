import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {NotFound} from 'components';
import {
  DecisionTree,
  Home,
  VerifyEmail,
  ResetPassword,
  AcceptInvitation,
  PrivacyPolicy,
  TermsAndConditions,
  AdminSignIn,
  AdminDashboard,
  AdminOrganizations,
  AdminOrganization,
  AdminOrganizationUsers,
  AdminUser,
  AdminRequests,
  AdminRequest,
  AdminAccount,
  AdminOrganizationItems,
  AdminOrganizationItem,
  AdminTech,
} from 'pages';

interface IRoutersProps {}

const Routers: React.FC<IRoutersProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/invitation" element={<AcceptInvitation />} />
      <Route path="/decision_tree/:decision_tree_id" element={<DecisionTree />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

      {/* Admin routes */}
      <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
      <Route path="/admin/sign_in" element={<AdminSignIn />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin/organizations" element={<AdminOrganizations />} />
      <Route path="/admin/organizations/:id/:name" element={<AdminOrganization />} />
      <Route path="/admin/organizations/:id/:name/users" element={<AdminOrganizationUsers />} />
      <Route path="/admin/organizations/:id/:name/items" element={<AdminOrganizationItems />} />
      <Route
        path="/admin/organizations/:id/:name/items/:itemId"
        element={<AdminOrganizationItem />}
      />
      <Route path="/admin/users/:id" element={<AdminUser />} />
      <Route path="/admin/requests" element={<AdminRequests />} />
      <Route path="/admin/requests/:id" element={<AdminRequest />} />
      <Route path="/admin/tech" element={<AdminTech />} />
      <Route path="/admin/account" element={<AdminAccount />} />

      {/* Unknown routes */}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
