import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import {ErrorType, ResType, TreeNodeType} from 'models';
import {NotFound} from 'components';

import Question from './components/Question';
import Items from './components/Items';

type ParamType = {
  id: string;
  decision_tree_id: string;
  answer: string;
};

interface IDecisionTreeProps {}

const DecisionTree: React.FC<IDecisionTreeProps> = () => {
  const navigate = useNavigate();

  const {decision_tree_id} = useParams();
  const [currentParam, setCurrentParam] = useState<ParamType>();
  const [currentData, setCurrentData] = useState<TreeNodeType>();
  const [errors, setErrors] = useState<ErrorType[]>();

  const {refetch: fetchRoot} = useQuery({
    queryKey: ['root_node', decision_tree_id],
    queryFn: () => axios.get<ResType<TreeNodeType>>(`/decision_trees/${decision_tree_id}/root`),
  });

  const {refetch: fetchNext, isFetching} = useQuery({
    queryKey: ['tree_node', currentParam?.id, currentParam?.decision_tree_id, currentParam?.answer],
    queryFn: () =>
      axios.get<ResType<TreeNodeType>>(
        `/tree_nodes/${currentParam?.id}?decision_tree_id=${currentParam?.decision_tree_id}&answer=${currentParam?.answer}`,
      ),
    enabled: false,
  });

  const handleConfirm = (answer: string) => {
    if (!decision_tree_id) return;
    if (!currentData?.question?.treeNodeId) return;

    setCurrentParam({
      id: currentData.question.treeNodeId,
      decision_tree_id,
      answer: encodeURIComponent(answer),
    });
  };

  const handleRestart = () => {
    navigate('/');
  };

  useEffect(() => {
    const fetchRootNode = async () => {
      const {data: res} = await fetchRoot();
      setCurrentData(res?.data?.data);
      setErrors(res?.data?.errors);
    };
    fetchRootNode();
  }, [fetchRoot]);

  useEffect(() => {
    const fetchNextNode = async () => {
      const {data: res} = await fetchNext();
      if (res?.data?.data) setCurrentData(res.data.data);
      setErrors(res?.data?.errors);
    };
    if (currentParam) fetchNextNode();
  }, [currentParam, fetchNext]);

  if (!decision_tree_id) return <NotFound />;
  if (!currentData) {
    return (
      <main className="flex flex-col justify-center items-center h-screen bg-[#151110] pb-40"></main>
    );
  }

  const {nodeType, question, items, answerType} = currentData;

  return (
    <main className="flex flex-col justify-center items-center h-screen bg-[#151110] pb-56 px-3">
      {nodeType === 'question' && !!question && (
        <Question
          key={question.id}
          answerType={answerType}
          inputError={errors}
          data={question}
          handlePress={handleConfirm}
          loading={isFetching}
        />
      )}
      {nodeType === 'items' && <Items data={items} handlePress={handleRestart} />}
    </main>
  );
};

export default DecisionTree;
