import React from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';

import {PostApiV1AdminAppWebsocketTestBody, usePostApiV1AdminAppWebsocketTest} from 'api/generated';
import {extractError} from 'utils';
import {Button, CardContainer, InputText} from 'components';

const schema: yup.Schema<PostApiV1AdminAppWebsocketTestBody> = yup.object().shape({
  channel_name: yup.string().required('Channel name cannot be blank'),
  data: yup.string().required('Data cannot be blank'),
});

interface IWebsocketTestProps {}

const WebsocketTest: React.FC<IWebsocketTestProps> = () => {
  const {
    control,
    handleSubmit: formSubmit,
    formState: {errors, isValid},
  } = useForm<PostApiV1AdminAppWebsocketTestBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      channel_name: '',
      data: '',
    },
  });

  const websocketTest = usePostApiV1AdminAppWebsocketTest();

  const handleSubmit = async (data: PostApiV1AdminAppWebsocketTestBody) => {
    const {data: res} = await websocketTest.mutateAsync({
      data: {channel_name: data.channel_name, data: data.data},
    });
    if (res.data?.success) {
      toast.success(res.data.message);
    } else {
      toast.error(extractError(res.errors));
    }
  };

  return (
    <CardContainer>
      <h1 className="text-lg font-semibold mb-4">Websocket Test</h1>
      <form onSubmit={formSubmit(handleSubmit)}>
        <Controller
          control={control}
          name="channel_name"
          render={({field: {value, onChange}}) => {
            return (
              <InputText
                label="Channel Name"
                placeholder="email_verifications_email"
                value={value}
                onChange={onChange}
                error={errors.channel_name?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="data"
          render={({field: {value, onChange}}) => {
            return (
              <InputText
                label="Data (JSON)"
                placeholder='{"email_verified": true}'
                value={value}
                onChange={onChange}
                error={errors.data?.message}
              />
            );
          }}
        />
        <Button text="Send" disabled={!isValid} />
      </form>
    </CardContainer>
  );
};

export default WebsocketTest;
