import React from 'react';
import {Link} from 'react-router-dom';
import {format} from 'date-fns';
import {DecisionHistorySimpleType} from 'api/generated';

interface IRequestItemContainerProps {
  data: DecisionHistorySimpleType;
}

const RequestItemContainer: React.FC<IRequestItemContainerProps> = ({
  data: {id, customerName, contactName, completedAt},
}) => {
  const date = format(new Date(completedAt as string), 'do MMM yyyy, hh:mm aaaa');
  return (
    <Link to={`/admin/requests/${id}`} className="hover:opacity-50 transition duration-200">
      <div className="bg-gray-100 p-2 md:p-4 rounded flex items-center justify-between mb-3">
        <p className="font-semibold">{customerName}</p>
        <p className="text-sm">{date}</p>
      </div>
    </Link>
  );
};

export default RequestItemContainer;
