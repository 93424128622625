import React from 'react';
import {useParams} from 'react-router-dom';
import {AdminPanel} from 'pages';

import {useGetApiV1ItemsThreeDModels} from 'api/generated';
import {noImage} from 'assets';
import {useTitle} from 'hooks';
import {BackButton, CardContainer, Img, ThreeModel} from 'components';

interface IAdminOrganizationItemProps {}

const AdminOrganizationItem: React.FC<IAdminOrganizationItemProps> = () => {
  const {id: organizationId, name: organizationName, itemId} = useParams();

  const {data: res} = useGetApiV1ItemsThreeDModels(
    {ids: [itemId as string]},
    {query: {staleTime: Infinity}},
  );
  const data = res?.data?.data?.[0];

  useTitle(data?.name ?? '');

  return (
    <AdminPanel title={data?.name ?? ''}>
      <BackButton
        fallbackPath={`/admin/organizations/${organizationId}/${organizationName}/items`}
      />
      {!data
        ? null
        : (() => {
            const {image, name, category, threeDModel} = data;
            const hasModel =
              !!threeDModel?.modelFile && !!threeDModel?.dataFile && !!threeDModel?.diffusedTexture;
            const list = [
              {key: 'Name', value: name},
              {key: 'Category', value: category},
            ];
            return (
              <div>
                <CardContainer className="py-3 md:py-3 mb-4">
                  {list.map(({key, value}) => {
                    return (
                      <div key={key} className="py-1.5 grid grid-cols-1 md:grid-cols-3 gap-x-4">
                        <p className="col-span-1">{key}:</p>
                        <p className="col-span-2 text-gray-600 break-all">{value}</p>
                      </div>
                    );
                  })}
                </CardContainer>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <CardContainer>
                      <div className="h-[28rem] flex justify-center items-center">
                        <Img
                          src={image ?? noImage}
                          alt={name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    </CardContainer>
                  </div>
                  {hasModel && (
                    <div className="col-span-1">
                      <CardContainer>
                        <div className="h-[28rem]">
                          <ThreeModel model={threeDModel} />
                        </div>
                      </CardContainer>
                    </div>
                  )}
                </div>
              </div>
            );
          })()}
    </AdminPanel>
  );
};

export default AdminOrganizationItem;
