import React from 'react';
import {motion} from 'framer-motion';

interface IImgProps {
  src: string;
  alt: string;
  className?: string;
  animate?: boolean;
}

const Img: React.FC<IImgProps> = ({src, alt, className = '', animate = true}) => {
  return (
    <motion.img
      src={src}
      alt={alt}
      className={className}
      loading="lazy"
      initial={{opacity: animate ? 0 : 1}}
      animate={{opacity: 1}}
      transition={{delay: 0.2, duration: 0.5}}
    />
  );
};

export default Img;
