import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {isDesktop} from 'react-device-detect';
import {DEEP_LINK_SCHEME} from 'config';

interface IAcceptInvitationProps {}

const AcceptInvitation: React.FC<IAcceptInvitationProps> = () => {
  const [params] = useSearchParams();
  const token = params.get('token');

  useEffect(() => {
    if (!isDesktop) {
      const deepLinkUrl = `${DEEP_LINK_SCHEME}://accept-invitation?token=${token}`;
      window.location.replace(deepLinkUrl);
    }
  }, [token]);

  // TODO: Show play/app store page if no mobile app installed.
  return (
    <div className="flex justify-center py-10">
      <p>Install the app to accept the invitation.</p>
    </div>
  );
};

export default AcceptInvitation;
