import React, {useReducer} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {AdminPanel} from 'pages';

import {useDeleteApiV1AdminUsersId, useGetApiV1AdminUsersId, useGetApiV1Me} from 'api/generated';
import {useTitle} from 'hooks';
import {
  BackButton,
  Button,
  CardContainer,
  ConfirmContainer,
  RequestItemContainer,
  Spacer,
} from 'components';

interface IAdminUserProps {}

const AdminUser: React.FC<IAdminUserProps> = () => {
  const navigate = useNavigate();

  useTitle('User');

  const [showDeleteModal, toggleDeleteModal] = useReducer(s => !s, false);

  const {id} = useParams();
  const {data: resMe} = useGetApiV1Me({query: {staleTime: Infinity}});
  const myRole = resMe?.data?.data?.role;

  const {data: res} = useGetApiV1AdminUsersId(id as string);
  const data = res?.data?.data;

  const deleteUser = useDeleteApiV1AdminUsersId();
  const handleDeleteUser = async () => {
    toggleDeleteModal();
    const {data: res} = await deleteUser.mutateAsync({id: id as string});
    if (res.data) navigate(-1);
  };

  return (
    <>
      <AdminPanel title={data?.email || 'User'}>
        <div className="flex items-center justify-between">
          <BackButton />
          {myRole === 'admin' && (
            <div className="mb-6">
              <Button
                text="Delete user"
                variant="secondary"
                handlePress={toggleDeleteModal}
                loading={deleteUser.isLoading}
              />
            </div>
          )}
        </div>

        {!data
          ? null
          : (() => {
              const {organizations, completedDecisionHistories} = data;
              return (
                <>
                  {!!organizations.length && (
                    <div className="mb-10">
                      <h2 className="text-xl font-medium mb-4">Organizations</h2>
                      <CardContainer className="py-4 md:py-4">
                        {organizations.map(({id, name}, idx) => {
                          return (
                            <Link
                              key={id}
                              to={`/admin/organizations/${id}/${name}`}
                              className="hover:opacity-50 transition duration-200">
                              <p>
                                {idx + 1}. {name}
                              </p>
                            </Link>
                          );
                        })}
                      </CardContainer>
                    </div>
                  )}

                  {!!completedDecisionHistories.length && (
                    <div className="mb-10">
                      <h2 className="text-xl font-medium mb-4">Requests</h2>
                      <CardContainer className="py-4 md:py-4">
                        {completedDecisionHistories.map(history => {
                          return <RequestItemContainer key={history.id} data={history} />;
                        })}
                      </CardContainer>
                    </div>
                  )}
                </>
              );
            })()}
      </AdminPanel>

      {showDeleteModal && (
        <ConfirmContainer>
          <p className="text-center pb-6">Are you sure you want to delete {data?.email}?</p>
          <Button text="Cancel" handlePress={toggleDeleteModal} />
          <Spacer verticalSpacing="my-2" />
          <Button text="Confirm" variant="secondary" handlePress={handleDeleteUser} />
        </ConfirmContainer>
      )}
    </>
  );
};

export default AdminUser;
