import React from 'react';

interface IInputTextProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  error?: string;
}

const InputText: React.FC<IInputTextProps> = props => {
  const {label, error, ...rest} = props;

  return (
    <div className="w-full mb-6">
      {!!label && <p className="text-sm text-gray-500 mb-1">{label}</p>}
      <input
        {...rest}
        className="px-4 py-2.5 rounded-md w-full border border-gray-300 focus:border-gray-500 focus:outline-none"
      />
      {!!error && <p className="text-xs text-red-500 mt-1.5 ml-0.5">{error}</p>}
    </div>
  );
};

export default InputText;
