import React from 'react';
import {useTitle} from 'hooks';
import {AdminPanel} from 'pages';
import {FolderIcon, UserGroupIcon} from '@heroicons/react/24/outline';
import {useGetApiV1AdminDashboard} from 'api/generated';
import {CardContainer} from 'components';

interface IAdminDashboardProps {}

const AdminDashboard: React.FC<IAdminDashboardProps> = () => {
  useTitle('Dashboard');

  const {data: res} = useGetApiV1AdminDashboard();
  const data = res?.data?.data;

  return (
    <AdminPanel title="Dashboard">
      {!!data && (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 mb-6">
            <CardContainer>
              <div className="flex items-center">
                <div className="bg-[#151110] rounded-md p-2.5 mr-4">
                  <UserGroupIcon className="text-white w-6 h-6" />
                </div>
                <div className="flex flex-col">
                  <p className="text-gray-500 text-sm">Total users</p>
                  <p className="text-2xl font-semibold">{data.usersCount}</p>
                </div>
              </div>
            </CardContainer>
            <CardContainer>
              <div className="flex items-center">
                <div className="bg-[#151110] rounded-md p-2.5 mr-4">
                  <FolderIcon className="text-white w-6 h-6" />
                </div>
                <div className="flex flex-col">
                  <p className="text-gray-500 text-sm">Total requests</p>
                  <p className="text-2xl font-semibold">{data.requestsCount}</p>
                </div>
              </div>
            </CardContainer>
          </div>
          <CardContainer>
            <p className="text-lg font-semibold mb-4">Most requested items</p>
            {Object.entries(data.mostRequestedItems).map(([name, count]) => {
              return (
                <div
                  key={`${name}-${count}`}
                  className="flex items-center justify-between py-3 border-b border-gray-300">
                  <p className="text-sm text-gray-500">{name}</p>
                  <p className="font-semibold">{count}x</p>
                </div>
              );
            })}
          </CardContainer>
        </div>
      )}
    </AdminPanel>
  );
};

export default AdminDashboard;
