import React from 'react';

interface IInputRadioProps {
  text: string;
  selected?: boolean;
  handleSelect: (text: string) => void;
}

const InputRadio: React.FC<IInputRadioProps> = ({text, selected = false, handleSelect}) => {
  return (
    <button
      type="button"
      className="flex items-center w-full py-2 hover:opacity-75"
      onClick={() => handleSelect(text)}>
      <div className="w-6 h-6 bg-white border border-black rounded-full flex justify-center items-center mr-3">
        {selected && <div className="w-[60%] h-[60%] bg-[#151110] rounded-full"></div>}
      </div>
      <p className="text-xl">{text}</p>
    </button>
  );
};

export default InputRadio;
