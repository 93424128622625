import React from 'react';

interface ISpacerProps {
  horizontalSpacing?: string;
  verticalSpacing?: string;
  divider?: boolean;
}

const Spacer: React.FC<ISpacerProps> = ({
  divider,
  horizontalSpacing = 'mx-0 md:mx-0',
  verticalSpacing = 'my-4 md:my-8',
}) => {
  const dividerStyles = divider ? 'h-[1px] bg-gray-300 w-full' : '';

  return <div className={`${verticalSpacing} ${horizontalSpacing} ${dividerStyles}`}></div>;
};

export default Spacer;
