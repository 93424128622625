import React from 'react';
import {AdminPanel} from 'pages';
import {useTitle} from 'hooks';

import WebsocketTest from './components/WebsocketTest';

interface IAdminTechProps {}

const AdminTech: React.FC<IAdminTechProps> = () => {
  useTitle('Tech');

  return (
    <AdminPanel title="Tech">
      <div>
        <WebsocketTest />
      </div>
    </AdminPanel>
  );
};

export default AdminTech;
