import {create} from 'zustand';
import {persist} from 'zustand/middleware';

const initialState = {
  authToken: '' as string,
  urlAfterAuth: '' as string,
};

type PersistentStoreState = typeof initialState & {
  setAuthToken: (authToken: string) => void;
  setUrlAfterAuth: (urlAfterAuth: string) => void;
  resetStore: () => void;
};

const usePersistentStore = create<PersistentStoreState>()(
  persist(
    (set, _get) => ({
      ...initialState,
      setAuthToken: authToken => set({authToken}),
      setUrlAfterAuth: urlAfterAuth => set({urlAfterAuth}),
      resetStore: () => set(initialState),
    }),
    {name: 'equipment_selection.storage'},
  ),
);

export default usePersistentStore;
