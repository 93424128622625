import React, {Suspense, useState} from 'react';
import {MeshPhongMaterial, MeshPhysicalMaterial} from 'three';
import {Canvas, useLoader} from '@react-three/fiber';
import {OrbitControls, useTexture} from '@react-three/drei';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader';

import {ThreeDModelType} from 'api/generated';
import {SvgEnlarge, SvgShrink} from 'components';

interface IThreeModelProps {
  model: ThreeDModelType;
}

const Scene: React.FC<IThreeModelProps> = ({model: {modelFile, dataFile, diffusedTexture}}) => {
  const map = useTexture(diffusedTexture as string);
  const mtl = useLoader(MTLLoader, dataFile as string);
  const obj = useLoader(OBJLoader, modelFile as string, loader => {
    // Prevent loading textures from the wrong paths stated inside mtl file.
    Object.entries(mtl.materialsInfo).forEach(([_name, materialInfo]) => {
      materialInfo.map_kd = ''; // Diffuse map.
      if (materialInfo.map_d) materialInfo.map_d = ''; // Alpha map.
    });

    mtl.preload();

    Object.entries(mtl.materials).forEach(([name, material]) => {
      const originalMaterial = material as MeshPhongMaterial;
      const convertedMaterial = new MeshPhysicalMaterial({
        name,
        map,
        transparent: mtl.materialsInfo[name].map_d !== undefined,
        flatShading: originalMaterial.flatShading,
        metalness: Math.max(((mtl.materialsInfo[name] as any)?.ka?.[0] ?? 0.3) - 0.3, 0),
        specularIntensity: Math.max(((mtl.materialsInfo[name] as any)?.ks?.[0] ?? 0.6) + 0.4, 1),
        // source: https://dcode.fr/function-equation-finder with power method.
        roughness: 1 - 0.0333334 * originalMaterial.shininess ** 0.5,
      });
      mtl.materials[name] = convertedMaterial;
    });

    loader.setMaterials(mtl);
  });

  return (
    <>
      <ambientLight intensity={0.2} />
      <spotLight position={[-15, 10, 10]} intensity={1.2} />
      <spotLight position={[12, 10, -10]} intensity={1} />
      <pointLight position={[0, 1, 5]} intensity={0.2} />
      <primitive object={obj} />
    </>
  );
};

const ThreeModel: React.FC<IThreeModelProps> = ({model}) => {
  const [isEnlarged, setIsEnlarged] = useState<boolean>(false);

  const handleControl = () => {
    setIsEnlarged(!isEnlarged);
  };

  const enlargedClass = 'fixed inset-0 bg-gray-300';
  const shrinkClass = 'relative w-full h-full bg-gray-300 rounded-lg';
  const containerClass = isEnlarged ? enlargedClass : shrinkClass;

  return (
    <div className={containerClass}>
      <Canvas>
        <Suspense fallback={null}>
          <Scene model={model} />
          <OrbitControls />
        </Suspense>
      </Canvas>
      <div
        className="absolute right-4 bottom-4 bg-gray-500 p-2 rounded cursor-pointer"
        onClick={handleControl}>
        {isEnlarged ? (
          <SvgShrink className="w-6 h-6 text-white" />
        ) : (
          <SvgEnlarge className="w-6 h-6 text-white" />
        )}
      </div>
    </div>
  );
};

export default ThreeModel;
