import {Fragment, PropsWithChildren, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {Link, useNavigate} from 'react-router-dom';
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  UsersIcon,
  CommandLineIcon,
  FolderIcon,
} from '@heroicons/react/24/outline';

import {useGetApiV1Me} from 'api/generated';
import {usePersistentStore} from 'stores';
import {Brand} from 'components';

const FIVE_MINUTES = 300 * 1000;

const navigation = [
  {name: 'Dashboard', href: '/admin/dashboard', icon: HomeIcon},
  {name: 'Organizations', href: '/admin/organizations', icon: UsersIcon},
  {name: 'Requests', href: '/admin/requests', icon: FolderIcon},
  {name: 'Tech', href: '/admin/tech', icon: CommandLineIcon},
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface IAdminPanelProps extends PropsWithChildren {
  title: string;
}

const AdminPanel: React.FC<IAdminPanelProps> = ({title, children}) => {
  const navigate = useNavigate();
  const authToken = usePersistentStore(s => s.authToken);
  const urlAfterAuth = usePersistentStore(s => s.urlAfterAuth);
  const setUrlAfterAuth = usePersistentStore(s => s.setUrlAfterAuth);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState<string>();

  const {data: res} = useGetApiV1Me({query: {enabled: !!authToken, staleTime: FIVE_MINUTES}});
  const data = res?.data?.data;

  useEffect(() => {
    if (window.location) setCurrentPath(window.location.pathname);
  }, []);

  useEffect(() => {
    if (!authToken) {
      const path = window.location.href.replace(window.location.origin, '');
      if (path !== '/admin/sign_in') setUrlAfterAuth(path);
      navigate('/admin/sign_in');
    }
  }, [authToken, urlAfterAuth, setUrlAfterAuth, navigate]);

  if (!authToken) return null;

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-[#151110]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Brand type="light" />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map(item => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          currentPath?.includes(item.href)
                            ? 'bg-[#024382] text-white'
                            : 'text-white hover:bg-[#1560a8] hover:bg-opacity-75',
                          'group flex items-center rounded-md px-2 py-2 text-base font-medium',
                        )}>
                        <item.icon
                          className="mr-4 h-6 w-6 flex-shrink-0 text-indigo-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 border-t border-[#024382] p-4">
                  <Link to="/admin/account" className="group block flex-shrink-0">
                    <p className="text-base font-medium text-white">{data?.email}</p>
                    <p className="text-sm font-medium text-indigo-200 group-hover:text-white">
                      Account settings
                    </p>
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component */}
        <div className="flex min-h-0 flex-1 flex-col bg-[#151110] bg-[#151110]">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <Brand type="light" />
            </div>
            <nav className="mt-5 flex-1 space-y-1 px-2">
              {navigation.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    currentPath?.includes(item.href)
                      ? 'bg-[#024382] text-white'
                      : 'text-white hover:bg-[#1560a8] hover:bg-opacity-75',
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                  )}>
                  <item.icon
                    className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300"
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>
          <div className="flex flex-shrink-0 border-t border-[#024382] p-4">
            <Link to="/admin/account" className="group block w-full flex-shrink-0">
              <p className="text-sm font-medium text-white">{data?.email}</p>
              <p className="text-xs font-medium text-indigo-200 group-hover:text-white">
                Account settings
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col lg:pl-64">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <main className="flex-1 min-h-screen bg-[#FBFBFB]">
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-2xl font-semibold text-gray-900 mb-4 md:mb-8 break-all">
                {title}
              </h1>
            </div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminPanel;
