import React, {PropsWithChildren} from 'react';
import {motion} from 'framer-motion';

interface IConfirmContainerProps extends PropsWithChildren {}

const ConfirmContainer: React.FC<IConfirmContainerProps> = ({children}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        className="bg-white p-6 mx-3 rounded-md"
        initial={{opacity: 0, translateY: 40}}
        animate={{opacity: 1, translateY: 0}}>
        {children}
      </motion.div>
    </div>
  );
};

export default ConfirmContainer;
