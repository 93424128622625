import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {AnswerType, ErrorType, QuestionType} from 'models';
import {Button, InputRadio, InputText} from 'components';

interface IQuestionProps {
  data: QuestionType<'select_one'>;
  answerType: AnswerType;
  inputError: ErrorType[] | undefined;
  handlePress: (answer: string) => void;
  loading?: boolean;
}

const Question: React.FC<IQuestionProps> = ({
  data: {id, treeNodeId, description, answers},
  answerType,
  inputError,
  handlePress,
  loading = false,
}) => {
  const [answer, setAnswer] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSelect = (value: string) => {
    setError('');
    setAnswer(value);
  };

  const handleConfirm = () => {
    answer ? handlePress(answer) : setError('Must choose an answer');
  };

  return (
    <motion.div
      className="bg-white py-14 px-3 w-full max-w-xs sm:max-w-sm rounded-md"
      transition={{type: 'spring', damping: 80, stiffness: 1000}}
      initial={{x: '100%', opacity: 0}}
      animate={{x: 0, opacity: 1}}>
      <div className="flex flex-col justify-center items-center">
        <p className="text-center text-3xl font-bold">{description}</p>
        {!!error && <p className="text-red-500 text-sm mt-2 text-center font-bold mt-1">{error}</p>}
        <div className="pt-6 pb-10 flex flex-col justify-center items-center">
          {answerType === 'select_one' &&
            answers.map(a => {
              return (
                <InputRadio
                  key={`${id}-${description}-${treeNodeId}-${a.value}`}
                  text={a.value}
                  selected={a.value === answer}
                  handleSelect={handleSelect}
                />
              );
            })}
          {answerType === 'range' && (
            <InputText
              type="number"
              step="1"
              min="1"
              placeholder="Example: 100"
              value={answer}
              onChange={e => setAnswer(e.target.value.replace(/[^0-9]/g, ''))}
            />
          )}
          {!!inputError && (
            <p className="mt-3 text-sm text-red-500 font-bold">{inputError[0].detail}</p>
          )}
        </div>
        <Button text="Confirm" handlePress={handleConfirm} loading={loading} />
      </div>
    </motion.div>
  );
};

export default Question;
