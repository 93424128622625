import React from 'react';
import {AdminPanel} from 'pages';
import {useTitle} from 'hooks';
import {useGetApiV1AdminDecisionHistories} from 'api/generated';
import {RequestItemContainer} from 'components';

interface IAdminRequestsProps {}

const AdminRequests: React.FC<IAdminRequestsProps> = () => {
  useTitle('Requests');

  const {data: res} = useGetApiV1AdminDecisionHistories();
  const data = res?.data?.data;

  return (
    <AdminPanel title="Requests">
      {!!data && (
        <div>
          {data.map(history => {
            return <RequestItemContainer key={history.id} data={history} />;
          })}
        </div>
      )}
    </AdminPanel>
  );
};

export default AdminRequests;
