import React from 'react';

const BG_COLOR_MAP: Record<TagType, string> = {
  success: 'bg-green-500',
  error: 'bg-red-500',
  warning: 'bg-yellow-500',
  info: 'bg-cyan-500',
};

export type TagType = 'success' | 'error' | 'warning' | 'info';

interface ITagProps {
  text: string;
  type?: TagType;
}

const Tag: React.FC<ITagProps> = ({text, type = 'info'}) => {
  if (!text) return null;

  const bgColor = BG_COLOR_MAP[type];

  return (
    <div className={`inline-block rounded-full px-4 py-1 ${bgColor}`}>
      <p className="text-center text-white text-xs">{text}</p>
    </div>
  );
};

export default Tag;
