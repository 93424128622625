import React from 'react';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {Tag, TagType} from 'components';

export type TableRowData = {
  key: string;
  id: string;
  data: {
    type: 'tag' | 'text';
    tagType?: TagType;
    text: string;
  }[];
};

interface ITableProps {
  columnTitles: string[];
  rowData: TableRowData[];
  handleClickRow: (id: string) => void;
}

const Table: React.FC<ITableProps> = ({columnTitles, rowData, handleClickRow}) => {
  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-white rounded-md shadow">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">A list of all the users in the organization.</p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {columnTitles.map(title => {
                    return (
                      <th
                        key={title}
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                        {title}
                      </th>
                    );
                  })}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Show</span>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {rowData.map(({key, id, data}, idx) => {
                  const disabled = !id;
                  const handleClick = disabled ? () => {} : () => handleClickRow(id);
                  const className = disabled
                    ? ''
                    : 'hover:bg-gray-100 transition duration-200 cursor-pointer';
                  return (
                    <tr
                      key={key}
                      onClick={handleClick}
                      className={`${className} ${idx % 2 === 0 ? undefined : 'bg-gray-50'}`}>
                      {data.map(({type, tagType, text}, rowColumnIdx) => {
                        return (
                          <td
                            key={`${text}-${rowColumnIdx}`}
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {type === 'tag' ? <Tag text={text} type={tagType} /> : text}
                          </td>
                        );
                      })}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        {disabled ? null : <ChevronRightIcon className="w-4 h-4" />}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Table);
