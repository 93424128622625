/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * ITEM FILTER API
 * OpenAPI spec version: v1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
export type PostApiV1UsersResetPassword200 = {
  data: UserType;
  errors: ErrorsType;
};

export type PostApiV1UsersResetPasswordBody = {
  token: string;
  password: string;
  passwordConfirmation: string;
};

export type PostApiV1UsersResetPasswordRequest200 = {
  data: SuccessType;
  errors: ErrorsType;
};

export type PostApiV1Users422Data = unknown | null;

export type PostApiV1Users422 = {
  data: PostApiV1Users422Data;
  errors: ErrorsType;
};

export type PostApiV1Users201 = {
  data: UserType;
  errors: ErrorsType;
};

export type PostApiV1UsersBody = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

export type GetApiV1Users200 = {
  data: OrganizationUserType;
  errors: ErrorsType;
};

export type GetApiV1UsersParams = { organization_id: string };

export type PostApiV1TreeNodesIdBack200 = {
  data: TreeNodeType;
  errors: ErrorsType;
};

export type PostApiV1TreeNodesIdBackBody = {
  decision_tree_id: string;
  decision_history_id: string;
};

export type PutApiV1TreeNodesId200 = {
  data: TreeNodeType;
  errors: ErrorsType;
};

export type PutApiV1TreeNodesIdBody = {
  decision_tree_id: string;
  decision_history_id: string;
  answer: unknown;
};

export type PutApiV1DecisionTreesIdRoot200 = {
  data: TreeNodeType;
  errors: ErrorsType;
};

export type PutApiV1DecisionTreesIdRootBody = {
  decision_history_id: string;
};

export type PostApiV1Session422Data = unknown | null;

export type PostApiV1Session422 = {
  data: PostApiV1Session422Data;
  errors: ErrorsType;
};

export type PostApiV1Session200DataAllOf = {
  jwt: string;
};

export type PostApiV1Session200Data = MeType & PostApiV1Session200DataAllOf;

export type PostApiV1Session200 = {
  data: PostApiV1Session200Data;
  errors: ErrorsType;
};

export type DeleteApiV1Me200 = {
  data: MeType;
  errors: ErrorsType;
};

export type GetApiV1Me200 = {
  data: MeType;
  errors: ErrorsType;
};

export type GetApiV1ItemsThreeDModels200 = {
  data: ItemWithModelType[];
  errors: ErrorsType;
};

export type GetApiV1ItemsThreeDModelsParams = { ids: string[] };

export type GetApiV1ItemsId200 = {
  data: ItemType;
  errors: ErrorsType;
};

export type GetApiV1Items200 = {
  data: ItemType[];
  errors: ErrorsType;
};

export type GetApiV1ItemsParams = { organization_id: string };

export type PostApiV1InvitationsAccept200 = {
  data: MeType;
  errors: ErrorsType;
};

export type PostApiV1Invitations200 = {
  data: SuccessType;
  errors: ErrorsType;
};

export type PostApiV1InvitationsBody = {
  organization_id: string;
  emails: string[];
};

export type PostApiV1Enquiries422Data = unknown | null;

export type PostApiV1Enquiries422 = {
  data: PostApiV1Enquiries422Data;
  errors: ErrorsType;
};

export type PostApiV1Enquiries201 = {
  data: EnquiryType;
  errors: ErrorsType;
};

export type PostApiV1EnquiriesBody = {
  subject: string;
  content: string;
};

export type PostApiV1EmailVerificationsResend200 = {
  data: SuccessType;
  errors: ErrorsType;
};

export type PutApiV1EmailVerifications200 = {
  data: SuccessType;
  errors: ErrorsType;
};

export type GetApiV1DecisionTreesId200 = {
  data: DecisionTreeType;
  errors: ErrorsType;
};

export type GetApiV1DecisionTrees200 = {
  data: OrganizationDecisionTreeType[];
  errors: ErrorsType;
};

export type PutApiV1DecisionHistoriesIdProceedAndSave200 = {
  data: DecisionHistorySimpleType;
  errors: ErrorsType;
};

export type GetApiV1DecisionHistoriesId200 = {
  data: DecisionHistoryType;
  errors: ErrorsType;
};

export type PostApiV1DecisionHistories422Data = unknown | null;

export type PostApiV1DecisionHistories422 = {
  data: PostApiV1DecisionHistories422Data;
  errors: ErrorsType;
};

export type PostApiV1DecisionHistories201 = {
  data: DecisionHistorySimpleType;
  errors: ErrorsType;
};

export type PostApiV1DecisionHistoriesBody = {
  customer_name: string;
  contact_name?: string;
  address?: string;
  phone?: string;
};

export type GetApiV1DecisionHistories200 = {
  data: DecisionHistorySimpleType[];
  errors: ErrorsType;
};

export type GetApiV1DecisionHistoriesState = typeof GetApiV1DecisionHistoriesState[keyof typeof GetApiV1DecisionHistoriesState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetApiV1DecisionHistoriesState = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export type GetApiV1DecisionHistoriesParams = { state: GetApiV1DecisionHistoriesState };

export type DeleteApiV1AdminUsersId200 = {
  data: AdminUserType;
  errors: ErrorsType;
};

export type GetApiV1AdminUsersId200 = {
  data: AdminUserType;
  errors: ErrorsType;
};

export type PostApiV1AdminSwaggerThreeDModelsIdAttachFiles200 = {
  data: ThreeDModelType;
  errors: ErrorsType;
};

export type PostApiV1AdminSwaggerThreeDModelsIdAttachFilesBody = {
  model_file: Blob;
  data_file: Blob;
  diffused_texture: Blob;
};

export type PostApiV1AdminSwaggerItemsIdAttachImage200 = {
  data: ItemType;
  errors: ErrorsType;
};

export type PostApiV1AdminSwaggerItemsIdAttachImageBody = {
  image: Blob;
};

export type PostApiV1AdminSession422Data = unknown | null;

export type PostApiV1AdminSession422 = {
  data: PostApiV1AdminSession422Data;
  errors: ErrorsType;
};

export type PostApiV1AdminSession200Data = {
  jwt: string;
};

export type PostApiV1AdminSession200 = {
  data: PostApiV1AdminSession200Data;
  errors: ErrorsType;
};

export type GetApiV1AdminDecisionHistoriesId200 = {
  data: AdminDecisionHistoryType;
  errors: ErrorsType;
};

export type GetApiV1AdminDecisionHistories200 = {
  data: DecisionHistorySimpleType[];
  errors: ErrorsType;
};

export type GetApiV1AdminDashboard200DataMostRequestedItems = {[key: string]: number};

export type GetApiV1AdminDashboard200Data = {
  usersCount: number;
  requestsCount: number;
  mostRequestedItems: GetApiV1AdminDashboard200DataMostRequestedItems;
};

export type GetApiV1AdminDashboard200 = {
  data?: GetApiV1AdminDashboard200Data;
  errors?: ErrorsType;
};

export type PostApiV1AdminAppWebsocketTest200 = {
  data: SuccessType;
  errors: ErrorsType;
};

export type PostApiV1AdminAppWebsocketTestBody = {
  channel_name: string;
  data: string;
};

export type User2Body = {
  email: string;
};

export type SessionBody = {
  email: string;
  password: string;
};

export type UserBody = {
  token: string;
};

export interface QuestionAnswersSelectOneType {
  value: string;
}

export interface QuestionAnswersRangeType {
  min: number;
  max: number;
}

export interface DecisionHistoryDetailsType {
  id: string;
  question: string;
  answer: string;
}

export type UserTypeRole = typeof UserTypeRole[keyof typeof UserTypeRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTypeRole = {
  admin: 'admin',
  user: 'user',
} as const;

export interface UserType {
  id: string;
  type: string;
  email: string;
  phone: string | null;
  role: UserTypeRole;
}

export type UserOrganizationTypeState = typeof UserOrganizationTypeState[keyof typeof UserOrganizationTypeState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserOrganizationTypeState = {
  pending: 'pending',
  active: 'active',
} as const;

export type UserOrganizationTypeRole = typeof UserOrganizationTypeRole[keyof typeof UserOrganizationTypeRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserOrganizationTypeRole = {
  admin: 'admin',
  user: 'user',
} as const;

export interface UserOrganizationType {
  id: string;
  type: string;
  role: UserOrganizationTypeRole;
  state: UserOrganizationTypeState;
  userEmail: string;
  user: UserType;
}

export type TreeNodeTypeAnswerType = typeof TreeNodeTypeAnswerType[keyof typeof TreeNodeTypeAnswerType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TreeNodeTypeAnswerType = {
  select_one: 'select_one',
  range: 'range',
} as const;

export type TreeNodeTypeNodeType = typeof TreeNodeTypeNodeType[keyof typeof TreeNodeTypeNodeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TreeNodeTypeNodeType = {
  question: 'question',
  items: 'items',
} as const;

export type ThreeDModelTypeModelType = typeof ThreeDModelTypeModelType[keyof typeof ThreeDModelTypeModelType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThreeDModelTypeModelType = {
  OBJ: 'OBJ',
} as const;

export interface ThreeDModelType {
  id: string;
  type: string;
  name: string;
  modelType: ThreeDModelTypeModelType;
  modelFile: string | null;
  dataFile: string | null;
  diffusedTexture: string | null;
}

export type QuestionTypeAnswers = QuestionAnswersSelectOneType[] | QuestionAnswersRangeType[];

export interface QuestionType {
  id: string;
  type: string;
  description: string | null;
  answers: QuestionTypeAnswers;
  treeNodeId: string | null;
}

export interface OrganizationUserType {
  id: string;
  type: string;
  name: string;
  userOrganizations: UserOrganizationType[];
}

export interface OrganizationType {
  id: string;
  type: string;
  name: string;
}

export interface OrganizationDecisionTreeType {
  id: string;
  type: string;
  name: string;
  decisionTrees: DecisionTreeType[];
}

export type MeTypeRole = typeof MeTypeRole[keyof typeof MeTypeRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeTypeRole = {
  admin: 'admin',
  user: 'user',
} as const;

export interface MeType {
  id: string;
  type: string;
  email: string;
  emailVerified: boolean;
  phone: string | null;
  role: MeTypeRole;
  organizations: OrganizationType[];
}

export interface ItemWithModelType {
  id: string;
  type: string;
  name: string;
  description: string | null;
  category: string | null;
  image: string | null;
  threeDModel: ThreeDModelType;
}

export interface ItemType {
  id: string;
  type: string;
  name: string;
  description: string | null;
  category: string | null;
  decisionTreeId: string;
  image: string | null;
}

export interface TreeNodeType {
  id: string;
  type: string;
  nodeType: TreeNodeTypeNodeType;
  answerType: TreeNodeTypeAnswerType;
  decisionTreeId: string;
  question: QuestionType;
  items: ItemType[];
}

export type EnquiryTypeSubject = typeof EnquiryTypeSubject[keyof typeof EnquiryTypeSubject];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnquiryTypeSubject = {
  business: 'business',
  tech: 'tech',
  others: 'others',
} as const;

export interface EnquiryType {
  id: string;
  type: string;
  subject: EnquiryTypeSubject;
  content: string;
}

export interface DecisionTreeType {
  id: string;
  type: string;
  name: string;
}

export interface DecisionHistorySimpleType {
  id: string;
  type: string;
  customerName: string;
  contactName: string | null;
  address: string | null;
  phone: string | null;
  completedAt: string | null;
}

export interface DecisionHistoryType {
  id: string;
  type: string;
  customerName: string;
  contactName: string | null;
  address: string | null;
  phone: string | null;
  completedAt: string | null;
  details: DecisionHistoryDetailsType[];
  items: ItemType[];
  images: string[];
}

export type AdminUserTypeRole = typeof AdminUserTypeRole[keyof typeof AdminUserTypeRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminUserTypeRole = {
  admin: 'admin',
  user: 'user',
} as const;

export interface AdminUserType {
  id: string;
  type: string;
  email: string;
  phone: string | null;
  role: AdminUserTypeRole;
  organizations: OrganizationType[];
  completedDecisionHistories: DecisionHistorySimpleType[];
  enquiries: EnquiryType[];
}

export interface AdminDecisionHistoryType {
  id: string;
  type: string;
  customerName: string;
  contactName: string | null;
  address: string | null;
  phone: string | null;
  completedAt: string | null;
  details: DecisionHistoryDetailsType[];
  user: UserType;
  items: ItemType[];
  images: string[];
}

export type ErrorsTypeItem = {
  field: string | null;
  detail: string;
};

export type ErrorsType = ErrorsTypeItem[] | null;

export type SuccessType = {
  success: boolean;
  message: string;
} | null;



type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * @summary Trigger websocket
 */
export const postApiV1AdminAppWebsocketTest = (
    postApiV1AdminAppWebsocketTestBody: PostApiV1AdminAppWebsocketTestBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1AdminAppWebsocketTest200>> => {
    return axios.post(
      `/api/v1/admin/app/websocket_test`,
      postApiV1AdminAppWebsocketTestBody,options
    );
  }



    export type PostApiV1AdminAppWebsocketTestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminAppWebsocketTest>>>
    export type PostApiV1AdminAppWebsocketTestMutationBody = PostApiV1AdminAppWebsocketTestBody
    export type PostApiV1AdminAppWebsocketTestMutationError = AxiosError<unknown>

    export const usePostApiV1AdminAppWebsocketTest = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminAppWebsocketTest>>, TError,{data: PostApiV1AdminAppWebsocketTestBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminAppWebsocketTest>>, {data: PostApiV1AdminAppWebsocketTestBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminAppWebsocketTest(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1AdminAppWebsocketTest>>, TError, {data: PostApiV1AdminAppWebsocketTestBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get dashboard
 */
export const getApiV1AdminDashboard = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1AdminDashboard200>> => {
    return axios.get(
      `/api/v1/admin/dashboard`,options
    );
  }


export const getGetApiV1AdminDashboardQueryKey = () => [`/api/v1/admin/dashboard`];

    
export type GetApiV1AdminDashboardQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminDashboard>>>
export type GetApiV1AdminDashboardQueryError = AxiosError<unknown>

export const useGetApiV1AdminDashboard = <TData = Awaited<ReturnType<typeof getApiV1AdminDashboard>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminDashboard>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1AdminDashboardQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminDashboard>>> = ({ signal }) => getApiV1AdminDashboard({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1AdminDashboard>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get list of histories
 */
export const getApiV1AdminDecisionHistories = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1AdminDecisionHistories200>> => {
    return axios.get(
      `/api/v1/admin/decision_histories`,options
    );
  }


export const getGetApiV1AdminDecisionHistoriesQueryKey = () => [`/api/v1/admin/decision_histories`];

    
export type GetApiV1AdminDecisionHistoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminDecisionHistories>>>
export type GetApiV1AdminDecisionHistoriesQueryError = AxiosError<unknown>

export const useGetApiV1AdminDecisionHistories = <TData = Awaited<ReturnType<typeof getApiV1AdminDecisionHistories>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminDecisionHistories>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1AdminDecisionHistoriesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminDecisionHistories>>> = ({ signal }) => getApiV1AdminDecisionHistories({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1AdminDecisionHistories>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get a history
 */
export const getApiV1AdminDecisionHistoriesId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1AdminDecisionHistoriesId200>> => {
    return axios.get(
      `/api/v1/admin/decision_histories/${id}`,options
    );
  }


export const getGetApiV1AdminDecisionHistoriesIdQueryKey = (id: string,) => [`/api/v1/admin/decision_histories/${id}`];

    
export type GetApiV1AdminDecisionHistoriesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminDecisionHistoriesId>>>
export type GetApiV1AdminDecisionHistoriesIdQueryError = AxiosError<unknown>

export const useGetApiV1AdminDecisionHistoriesId = <TData = Awaited<ReturnType<typeof getApiV1AdminDecisionHistoriesId>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminDecisionHistoriesId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1AdminDecisionHistoriesIdQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminDecisionHistoriesId>>> = ({ signal }) => getApiV1AdminDecisionHistoriesId(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1AdminDecisionHistoriesId>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Login and get JWT
 */
export const postApiV1AdminSession = (
    sessionBody: SessionBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1AdminSession200>> => {
    return axios.post(
      `/api/v1/admin/session`,
      sessionBody,options
    );
  }



    export type PostApiV1AdminSessionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminSession>>>
    export type PostApiV1AdminSessionMutationBody = SessionBody
    export type PostApiV1AdminSessionMutationError = AxiosError<PostApiV1AdminSession422>

    export const usePostApiV1AdminSession = <TError = AxiosError<PostApiV1AdminSession422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminSession>>, TError,{data: SessionBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminSession>>, {data: SessionBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminSession(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1AdminSession>>, TError, {data: SessionBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Attach an image to an item
 */
export const postApiV1AdminSwaggerItemsIdAttachImage = (
    id: string,
    postApiV1AdminSwaggerItemsIdAttachImageBody: PostApiV1AdminSwaggerItemsIdAttachImageBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1AdminSwaggerItemsIdAttachImage200>> => {const formData = new FormData();
formData.append('image', postApiV1AdminSwaggerItemsIdAttachImageBody.image)

    return axios.post(
      `/api/v1/admin/swagger/items/${id}/attach_image`,
      formData,options
    );
  }



    export type PostApiV1AdminSwaggerItemsIdAttachImageMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminSwaggerItemsIdAttachImage>>>
    export type PostApiV1AdminSwaggerItemsIdAttachImageMutationBody = PostApiV1AdminSwaggerItemsIdAttachImageBody
    export type PostApiV1AdminSwaggerItemsIdAttachImageMutationError = AxiosError<unknown>

    export const usePostApiV1AdminSwaggerItemsIdAttachImage = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminSwaggerItemsIdAttachImage>>, TError,{id: string;data: PostApiV1AdminSwaggerItemsIdAttachImageBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminSwaggerItemsIdAttachImage>>, {id: string;data: PostApiV1AdminSwaggerItemsIdAttachImageBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postApiV1AdminSwaggerItemsIdAttachImage(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1AdminSwaggerItemsIdAttachImage>>, TError, {id: string;data: PostApiV1AdminSwaggerItemsIdAttachImageBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Attach files to a 3D model
 */
export const postApiV1AdminSwaggerThreeDModelsIdAttachFiles = (
    id: string,
    postApiV1AdminSwaggerThreeDModelsIdAttachFilesBody: PostApiV1AdminSwaggerThreeDModelsIdAttachFilesBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1AdminSwaggerThreeDModelsIdAttachFiles200>> => {const formData = new FormData();
formData.append('model_file', postApiV1AdminSwaggerThreeDModelsIdAttachFilesBody.model_file)
formData.append('data_file', postApiV1AdminSwaggerThreeDModelsIdAttachFilesBody.data_file)
formData.append('diffused_texture', postApiV1AdminSwaggerThreeDModelsIdAttachFilesBody.diffused_texture)

    return axios.post(
      `/api/v1/admin/swagger/three_d_models/${id}/attach_files`,
      formData,options
    );
  }



    export type PostApiV1AdminSwaggerThreeDModelsIdAttachFilesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminSwaggerThreeDModelsIdAttachFiles>>>
    export type PostApiV1AdminSwaggerThreeDModelsIdAttachFilesMutationBody = PostApiV1AdminSwaggerThreeDModelsIdAttachFilesBody
    export type PostApiV1AdminSwaggerThreeDModelsIdAttachFilesMutationError = AxiosError<unknown>

    export const usePostApiV1AdminSwaggerThreeDModelsIdAttachFiles = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminSwaggerThreeDModelsIdAttachFiles>>, TError,{id: string;data: PostApiV1AdminSwaggerThreeDModelsIdAttachFilesBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminSwaggerThreeDModelsIdAttachFiles>>, {id: string;data: PostApiV1AdminSwaggerThreeDModelsIdAttachFilesBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postApiV1AdminSwaggerThreeDModelsIdAttachFiles(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1AdminSwaggerThreeDModelsIdAttachFiles>>, TError, {id: string;data: PostApiV1AdminSwaggerThreeDModelsIdAttachFilesBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get a user
 */
export const getApiV1AdminUsersId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1AdminUsersId200>> => {
    return axios.get(
      `/api/v1/admin/users/${id}`,options
    );
  }


export const getGetApiV1AdminUsersIdQueryKey = (id: string,) => [`/api/v1/admin/users/${id}`];

    
export type GetApiV1AdminUsersIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminUsersId>>>
export type GetApiV1AdminUsersIdQueryError = AxiosError<unknown>

export const useGetApiV1AdminUsersId = <TData = Awaited<ReturnType<typeof getApiV1AdminUsersId>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminUsersId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1AdminUsersIdQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminUsersId>>> = ({ signal }) => getApiV1AdminUsersId(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1AdminUsersId>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Destroy a user
 */
export const deleteApiV1AdminUsersId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteApiV1AdminUsersId200>> => {
    return axios.delete(
      `/api/v1/admin/users/${id}`,options
    );
  }



    export type DeleteApiV1AdminUsersIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminUsersId>>>
    
    export type DeleteApiV1AdminUsersIdMutationError = AxiosError<unknown>

    export const useDeleteApiV1AdminUsersId = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminUsersId>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminUsersId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiV1AdminUsersId(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteApiV1AdminUsersId>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get list of histories
 */
export const getApiV1DecisionHistories = (
    params: GetApiV1DecisionHistoriesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1DecisionHistories200>> => {
    return axios.get(
      `/api/v1/decision_histories`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1DecisionHistoriesQueryKey = (params: GetApiV1DecisionHistoriesParams,) => [`/api/v1/decision_histories`, ...(params ? [params]: [])];

    
export type GetApiV1DecisionHistoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1DecisionHistories>>>
export type GetApiV1DecisionHistoriesQueryError = AxiosError<unknown>

export const useGetApiV1DecisionHistories = <TData = Awaited<ReturnType<typeof getApiV1DecisionHistories>>, TError = AxiosError<unknown>>(
 params: GetApiV1DecisionHistoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DecisionHistories>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1DecisionHistoriesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1DecisionHistories>>> = ({ signal }) => getApiV1DecisionHistories(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1DecisionHistories>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Create a history
 */
export const postApiV1DecisionHistories = (
    postApiV1DecisionHistoriesBody: PostApiV1DecisionHistoriesBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1DecisionHistories201>> => {
    return axios.post(
      `/api/v1/decision_histories`,
      postApiV1DecisionHistoriesBody,options
    );
  }



    export type PostApiV1DecisionHistoriesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DecisionHistories>>>
    export type PostApiV1DecisionHistoriesMutationBody = PostApiV1DecisionHistoriesBody
    export type PostApiV1DecisionHistoriesMutationError = AxiosError<PostApiV1DecisionHistories422>

    export const usePostApiV1DecisionHistories = <TError = AxiosError<PostApiV1DecisionHistories422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DecisionHistories>>, TError,{data: PostApiV1DecisionHistoriesBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DecisionHistories>>, {data: PostApiV1DecisionHistoriesBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1DecisionHistories(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1DecisionHistories>>, TError, {data: PostApiV1DecisionHistoriesBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get a history
 */
export const getApiV1DecisionHistoriesId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1DecisionHistoriesId200>> => {
    return axios.get(
      `/api/v1/decision_histories/${id}`,options
    );
  }


export const getGetApiV1DecisionHistoriesIdQueryKey = (id: string,) => [`/api/v1/decision_histories/${id}`];

    
export type GetApiV1DecisionHistoriesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1DecisionHistoriesId>>>
export type GetApiV1DecisionHistoriesIdQueryError = AxiosError<unknown>

export const useGetApiV1DecisionHistoriesId = <TData = Awaited<ReturnType<typeof getApiV1DecisionHistoriesId>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DecisionHistoriesId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1DecisionHistoriesIdQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1DecisionHistoriesId>>> = ({ signal }) => getApiV1DecisionHistoriesId(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1DecisionHistoriesId>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Proceed and save a decision history
 */
export const putApiV1DecisionHistoriesIdProceedAndSave = (
    id: string,
    putApiV1DecisionHistoriesIdProceedAndSaveBody: unknown, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PutApiV1DecisionHistoriesIdProceedAndSave200>> => {
    return axios.put(
      `/api/v1/decision_histories/${id}/proceed_and_save`,
      putApiV1DecisionHistoriesIdProceedAndSaveBody,options
    );
  }



    export type PutApiV1DecisionHistoriesIdProceedAndSaveMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1DecisionHistoriesIdProceedAndSave>>>
    export type PutApiV1DecisionHistoriesIdProceedAndSaveMutationBody = unknown
    export type PutApiV1DecisionHistoriesIdProceedAndSaveMutationError = AxiosError<unknown>

    export const usePutApiV1DecisionHistoriesIdProceedAndSave = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DecisionHistoriesIdProceedAndSave>>, TError,{id: string;data: unknown}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1DecisionHistoriesIdProceedAndSave>>, {id: string;data: unknown}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiV1DecisionHistoriesIdProceedAndSave(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1DecisionHistoriesIdProceedAndSave>>, TError, {id: string;data: unknown}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get list of trees
 */
export const getApiV1DecisionTrees = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1DecisionTrees200>> => {
    return axios.get(
      `/api/v1/decision_trees`,options
    );
  }


export const getGetApiV1DecisionTreesQueryKey = () => [`/api/v1/decision_trees`];

    
export type GetApiV1DecisionTreesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1DecisionTrees>>>
export type GetApiV1DecisionTreesQueryError = AxiosError<unknown>

export const useGetApiV1DecisionTrees = <TData = Awaited<ReturnType<typeof getApiV1DecisionTrees>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DecisionTrees>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1DecisionTreesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1DecisionTrees>>> = ({ signal }) => getApiV1DecisionTrees({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1DecisionTrees>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get a tree
 */
export const getApiV1DecisionTreesId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1DecisionTreesId200>> => {
    return axios.get(
      `/api/v1/decision_trees/${id}`,options
    );
  }


export const getGetApiV1DecisionTreesIdQueryKey = (id: string,) => [`/api/v1/decision_trees/${id}`];

    
export type GetApiV1DecisionTreesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1DecisionTreesId>>>
export type GetApiV1DecisionTreesIdQueryError = AxiosError<unknown>

export const useGetApiV1DecisionTreesId = <TData = Awaited<ReturnType<typeof getApiV1DecisionTreesId>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DecisionTreesId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1DecisionTreesIdQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1DecisionTreesId>>> = ({ signal }) => getApiV1DecisionTreesId(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1DecisionTreesId>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Verify email
 */
export const putApiV1EmailVerifications = (
    userBody: UserBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PutApiV1EmailVerifications200>> => {
    return axios.put(
      `/api/v1/email_verifications`,
      userBody,options
    );
  }



    export type PutApiV1EmailVerificationsMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1EmailVerifications>>>
    export type PutApiV1EmailVerificationsMutationBody = UserBody
    export type PutApiV1EmailVerificationsMutationError = AxiosError<unknown>

    export const usePutApiV1EmailVerifications = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1EmailVerifications>>, TError,{data: UserBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1EmailVerifications>>, {data: UserBody}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1EmailVerifications(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1EmailVerifications>>, TError, {data: UserBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Resend verification email
 */
export const postApiV1EmailVerificationsResend = (
    user2Body: User2Body, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1EmailVerificationsResend200>> => {
    return axios.post(
      `/api/v1/email_verifications/resend`,
      user2Body,options
    );
  }



    export type PostApiV1EmailVerificationsResendMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1EmailVerificationsResend>>>
    export type PostApiV1EmailVerificationsResendMutationBody = User2Body
    export type PostApiV1EmailVerificationsResendMutationError = AxiosError<unknown>

    export const usePostApiV1EmailVerificationsResend = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1EmailVerificationsResend>>, TError,{data: User2Body}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1EmailVerificationsResend>>, {data: User2Body}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1EmailVerificationsResend(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1EmailVerificationsResend>>, TError, {data: User2Body}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Create an enquiry
 */
export const postApiV1Enquiries = (
    postApiV1EnquiriesBody: PostApiV1EnquiriesBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1Enquiries201>> => {
    return axios.post(
      `/api/v1/enquiries`,
      postApiV1EnquiriesBody,options
    );
  }



    export type PostApiV1EnquiriesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Enquiries>>>
    export type PostApiV1EnquiriesMutationBody = PostApiV1EnquiriesBody
    export type PostApiV1EnquiriesMutationError = AxiosError<PostApiV1Enquiries422>

    export const usePostApiV1Enquiries = <TError = AxiosError<PostApiV1Enquiries422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Enquiries>>, TError,{data: PostApiV1EnquiriesBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Enquiries>>, {data: PostApiV1EnquiriesBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Enquiries(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Enquiries>>, TError, {data: PostApiV1EnquiriesBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Invite a list of users by email
 */
export const postApiV1Invitations = (
    postApiV1InvitationsBody: PostApiV1InvitationsBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1Invitations200>> => {
    return axios.post(
      `/api/v1/invitations`,
      postApiV1InvitationsBody,options
    );
  }



    export type PostApiV1InvitationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Invitations>>>
    export type PostApiV1InvitationsMutationBody = PostApiV1InvitationsBody
    export type PostApiV1InvitationsMutationError = AxiosError<unknown>

    export const usePostApiV1Invitations = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Invitations>>, TError,{data: PostApiV1InvitationsBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Invitations>>, {data: PostApiV1InvitationsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Invitations(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Invitations>>, TError, {data: PostApiV1InvitationsBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Accept an invitation
 */
export const postApiV1InvitationsAccept = (
    userBody: UserBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1InvitationsAccept200>> => {
    return axios.post(
      `/api/v1/invitations/accept`,
      userBody,options
    );
  }



    export type PostApiV1InvitationsAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1InvitationsAccept>>>
    export type PostApiV1InvitationsAcceptMutationBody = UserBody
    export type PostApiV1InvitationsAcceptMutationError = AxiosError<unknown>

    export const usePostApiV1InvitationsAccept = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1InvitationsAccept>>, TError,{data: UserBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1InvitationsAccept>>, {data: UserBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1InvitationsAccept(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1InvitationsAccept>>, TError, {data: UserBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get list of items
 */
export const getApiV1Items = (
    params: GetApiV1ItemsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Items200>> => {
    return axios.get(
      `/api/v1/items`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1ItemsQueryKey = (params: GetApiV1ItemsParams,) => [`/api/v1/items`, ...(params ? [params]: [])];

    
export type GetApiV1ItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Items>>>
export type GetApiV1ItemsQueryError = AxiosError<unknown>

export const useGetApiV1Items = <TData = Awaited<ReturnType<typeof getApiV1Items>>, TError = AxiosError<unknown>>(
 params: GetApiV1ItemsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Items>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1ItemsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Items>>> = ({ signal }) => getApiV1Items(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Items>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get an item
 */
export const getApiV1ItemsId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1ItemsId200>> => {
    return axios.get(
      `/api/v1/items/${id}`,options
    );
  }


export const getGetApiV1ItemsIdQueryKey = (id: string,) => [`/api/v1/items/${id}`];

    
export type GetApiV1ItemsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ItemsId>>>
export type GetApiV1ItemsIdQueryError = AxiosError<unknown>

export const useGetApiV1ItemsId = <TData = Awaited<ReturnType<typeof getApiV1ItemsId>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1ItemsId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1ItemsIdQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ItemsId>>> = ({ signal }) => getApiV1ItemsId(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1ItemsId>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get items with 3D models
 */
export const getApiV1ItemsThreeDModels = (
    params: GetApiV1ItemsThreeDModelsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1ItemsThreeDModels200>> => {
    return axios.get(
      `/api/v1/items/three_d_models`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1ItemsThreeDModelsQueryKey = (params: GetApiV1ItemsThreeDModelsParams,) => [`/api/v1/items/three_d_models`, ...(params ? [params]: [])];

    
export type GetApiV1ItemsThreeDModelsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ItemsThreeDModels>>>
export type GetApiV1ItemsThreeDModelsQueryError = AxiosError<unknown>

export const useGetApiV1ItemsThreeDModels = <TData = Awaited<ReturnType<typeof getApiV1ItemsThreeDModels>>, TError = AxiosError<unknown>>(
 params: GetApiV1ItemsThreeDModelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1ItemsThreeDModels>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1ItemsThreeDModelsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ItemsThreeDModels>>> = ({ signal }) => getApiV1ItemsThreeDModels(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1ItemsThreeDModels>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get me
 */
export const getApiV1Me = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Me200>> => {
    return axios.get(
      `/api/v1/me`,options
    );
  }


export const getGetApiV1MeQueryKey = () => [`/api/v1/me`];

    
export type GetApiV1MeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Me>>>
export type GetApiV1MeQueryError = AxiosError<unknown>

export const useGetApiV1Me = <TData = Awaited<ReturnType<typeof getApiV1Me>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Me>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1MeQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Me>>> = ({ signal }) => getApiV1Me({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Me>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Delete me
 */
export const deleteApiV1Me = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteApiV1Me200>> => {
    return axios.delete(
      `/api/v1/me`,options
    );
  }



    export type DeleteApiV1MeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1Me>>>
    
    export type DeleteApiV1MeMutationError = AxiosError<unknown>

    export const useDeleteApiV1Me = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1Me>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1Me>>, TVariables> = () => {
          

          return  deleteApiV1Me(axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteApiV1Me>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Login and get JWT
 */
export const postApiV1Session = (
    sessionBody: SessionBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1Session200>> => {
    return axios.post(
      `/api/v1/session`,
      sessionBody,options
    );
  }



    export type PostApiV1SessionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Session>>>
    export type PostApiV1SessionMutationBody = SessionBody
    export type PostApiV1SessionMutationError = AxiosError<PostApiV1Session422>

    export const usePostApiV1Session = <TError = AxiosError<PostApiV1Session422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Session>>, TError,{data: SessionBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Session>>, {data: SessionBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Session(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Session>>, TError, {data: SessionBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get the root node of a tree
 */
export const putApiV1DecisionTreesIdRoot = (
    id: string,
    putApiV1DecisionTreesIdRootBody: PutApiV1DecisionTreesIdRootBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PutApiV1DecisionTreesIdRoot200>> => {
    return axios.put(
      `/api/v1/decision_trees/${id}/root`,
      putApiV1DecisionTreesIdRootBody,options
    );
  }



    export type PutApiV1DecisionTreesIdRootMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1DecisionTreesIdRoot>>>
    export type PutApiV1DecisionTreesIdRootMutationBody = PutApiV1DecisionTreesIdRootBody
    export type PutApiV1DecisionTreesIdRootMutationError = AxiosError<unknown>

    export const usePutApiV1DecisionTreesIdRoot = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DecisionTreesIdRoot>>, TError,{id: string;data: PutApiV1DecisionTreesIdRootBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1DecisionTreesIdRoot>>, {id: string;data: PutApiV1DecisionTreesIdRootBody}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiV1DecisionTreesIdRoot(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1DecisionTreesIdRoot>>, TError, {id: string;data: PutApiV1DecisionTreesIdRootBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get a non-root node
 */
export const putApiV1TreeNodesId = (
    id: string,
    putApiV1TreeNodesIdBody: PutApiV1TreeNodesIdBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PutApiV1TreeNodesId200>> => {
    return axios.put(
      `/api/v1/tree_nodes/${id}`,
      putApiV1TreeNodesIdBody,options
    );
  }



    export type PutApiV1TreeNodesIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1TreeNodesId>>>
    export type PutApiV1TreeNodesIdMutationBody = PutApiV1TreeNodesIdBody
    export type PutApiV1TreeNodesIdMutationError = AxiosError<unknown>

    export const usePutApiV1TreeNodesId = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1TreeNodesId>>, TError,{id: string;data: PutApiV1TreeNodesIdBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1TreeNodesId>>, {id: string;data: PutApiV1TreeNodesIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiV1TreeNodesId(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1TreeNodesId>>, TError, {id: string;data: PutApiV1TreeNodesIdBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Back to prev node
 */
export const postApiV1TreeNodesIdBack = (
    id: string,
    postApiV1TreeNodesIdBackBody: PostApiV1TreeNodesIdBackBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1TreeNodesIdBack200>> => {
    return axios.post(
      `/api/v1/tree_nodes/${id}/back`,
      postApiV1TreeNodesIdBackBody,options
    );
  }



    export type PostApiV1TreeNodesIdBackMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1TreeNodesIdBack>>>
    export type PostApiV1TreeNodesIdBackMutationBody = PostApiV1TreeNodesIdBackBody
    export type PostApiV1TreeNodesIdBackMutationError = AxiosError<unknown>

    export const usePostApiV1TreeNodesIdBack = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1TreeNodesIdBack>>, TError,{id: string;data: PostApiV1TreeNodesIdBackBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1TreeNodesIdBack>>, {id: string;data: PostApiV1TreeNodesIdBackBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postApiV1TreeNodesIdBack(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1TreeNodesIdBack>>, TError, {id: string;data: PostApiV1TreeNodesIdBackBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get list of users from an organization
 */
export const getApiV1Users = (
    params: GetApiV1UsersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApiV1Users200>> => {
    return axios.get(
      `/api/v1/users`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1UsersQueryKey = (params: GetApiV1UsersParams,) => [`/api/v1/users`, ...(params ? [params]: [])];

    
export type GetApiV1UsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Users>>>
export type GetApiV1UsersQueryError = AxiosError<unknown>

export const useGetApiV1Users = <TData = Awaited<ReturnType<typeof getApiV1Users>>, TError = AxiosError<unknown>>(
 params: GetApiV1UsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Users>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1UsersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Users>>> = ({ signal }) => getApiV1Users(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Users>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Sign up and create a user
 */
export const postApiV1Users = (
    postApiV1UsersBody: PostApiV1UsersBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1Users201>> => {
    return axios.post(
      `/api/v1/users`,
      postApiV1UsersBody,options
    );
  }



    export type PostApiV1UsersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Users>>>
    export type PostApiV1UsersMutationBody = PostApiV1UsersBody
    export type PostApiV1UsersMutationError = AxiosError<PostApiV1Users422>

    export const usePostApiV1Users = <TError = AxiosError<PostApiV1Users422>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Users>>, TError,{data: PostApiV1UsersBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Users>>, {data: PostApiV1UsersBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Users(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Users>>, TError, {data: PostApiV1UsersBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary User reset password request
 */
export const postApiV1UsersResetPasswordRequest = (
    user2Body: User2Body, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1UsersResetPasswordRequest200>> => {
    return axios.post(
      `/api/v1/users/reset_password_request`,
      user2Body,options
    );
  }



    export type PostApiV1UsersResetPasswordRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1UsersResetPasswordRequest>>>
    export type PostApiV1UsersResetPasswordRequestMutationBody = User2Body
    export type PostApiV1UsersResetPasswordRequestMutationError = AxiosError<unknown>

    export const usePostApiV1UsersResetPasswordRequest = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1UsersResetPasswordRequest>>, TError,{data: User2Body}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1UsersResetPasswordRequest>>, {data: User2Body}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1UsersResetPasswordRequest(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1UsersResetPasswordRequest>>, TError, {data: User2Body}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary User reset password
 */
export const postApiV1UsersResetPassword = (
    postApiV1UsersResetPasswordBody: PostApiV1UsersResetPasswordBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PostApiV1UsersResetPassword200>> => {
    return axios.post(
      `/api/v1/users/reset_password`,
      postApiV1UsersResetPasswordBody,options
    );
  }



    export type PostApiV1UsersResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1UsersResetPassword>>>
    export type PostApiV1UsersResetPasswordMutationBody = PostApiV1UsersResetPasswordBody
    export type PostApiV1UsersResetPasswordMutationError = AxiosError<unknown>

    export const usePostApiV1UsersResetPassword = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1UsersResetPassword>>, TError,{data: PostApiV1UsersResetPasswordBody}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1UsersResetPassword>>, {data: PostApiV1UsersResetPasswordBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1UsersResetPassword(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1UsersResetPassword>>, TError, {data: PostApiV1UsersResetPasswordBody}, TContext>(mutationFn, mutationOptions)
    }
    
