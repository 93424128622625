import React from 'react';
import {Link} from 'react-router-dom';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {AdminPanel} from 'pages';

import {useGetApiV1Me} from 'api/generated';
import {useTitle} from 'hooks';
import {CardContainer} from 'components';

interface IAdminOrganizationsProps {}

const AdminOrganizations: React.FC<IAdminOrganizationsProps> = () => {
  useTitle('Organizations');

  const {data: res} = useGetApiV1Me({query: {enabled: false}});
  const data = res?.data?.data;
  const organizations = data?.organizations || [];

  return (
    <AdminPanel title="Organizations">
      {organizations.map(({id, name}) => {
        return (
          <Link
            key={id}
            to={`/admin/organizations/${id}/${name}`}
            className="w-full transition duration-200 hover:opacity-50">
            <CardContainer className="flex items-center justify-between md:py-4 mb-4">
              <p className="text-lg font-semibold">{name}</p>
              <ChevronRightIcon className="w-6 h-6" />
            </CardContainer>
          </Link>
        );
      })}
    </AdminPanel>
  );
};

export default AdminOrganizations;
