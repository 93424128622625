import React from 'react';
import {AdminPanel} from 'pages';
import {PowerIcon} from '@heroicons/react/24/outline';

import {useGetApiV1Me} from 'api/generated';
import {useTitle} from 'hooks';
import {usePersistentStore} from 'stores';
import {CardContainer, Spacer} from 'components';

interface IAdminAccountProps {}

const AdminAccount: React.FC<IAdminAccountProps> = () => {
  useTitle('Account');

  const resetStore = usePersistentStore(s => s.resetStore);
  const {data: res} = useGetApiV1Me({query: {enabled: false}});
  const data = res?.data?.data;

  const details = [
    {key: 'Email', value: data?.email ?? '', capitalize: false},
    {key: 'Role', value: data?.role ?? '', capitalize: true},
  ];

  const handleLogout = () => {
    resetStore();
  };

  return (
    <AdminPanel title="Account">
      <h2 className="text-lg mb-2">Details</h2>
      <CardContainer className="md:px-6 md:py-3">
        {details.map(({key, value, capitalize}) => {
          return (
            <div key={key} className="grid grid-cols-1 md:grid-cols-7 gap-x-4 py-3">
              <p className="col-span-2 text-gray-600 text-sm">{key}:</p>
              <p className={`col-span-5 text-sm break-all ${capitalize ? 'capitalize' : ''}`}>
                {value}
              </p>
            </div>
          );
        })}
      </CardContainer>

      <Spacer />

      <h2 className="text-lg mb-2">Organizations</h2>
      <CardContainer className="md:px-6 md:py-3">
        {data?.organizations?.map(({id, name}, index) => {
          return (
            <div key={id} className="py-3">
              <p className="text-sm">
                {index + 1}. {name}
              </p>
            </div>
          );
        })}
      </CardContainer>

      <Spacer />

      <div className="flex justify-end">
        <button
          type="button"
          className="hover:opacity-75 transition duration-300 flex items-center"
          onClick={handleLogout}>
          <PowerIcon className="mr-2 h-6 w-6 flex-shrink-0 text-red-500" aria-hidden="true" />
          <p className="text-red-500 text-sm font-medium">Log out</p>
        </button>
      </div>
    </AdminPanel>
  );
};

export default AdminAccount;
