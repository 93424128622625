import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {useSearchParams} from 'react-router-dom';
import {isDesktop} from 'react-device-detect';

import {usePutApiV1EmailVerifications} from 'api/generated';
import {DEEP_LINK_SCHEME} from 'config';
import {useTitle} from 'hooks';

interface IVerifyEmailProps {}

const VerifyEmail: React.FC<IVerifyEmailProps> = () => {
  useTitle('Verify email');

  const [params] = useSearchParams();
  const token = params.get('token');

  const verifyEmail = usePutApiV1EmailVerifications();
  const [emailVerified, setEmailVerified] = useState<'true' | 'false' | 'pending'>('pending');

  useEffect(() => {
    if (!isDesktop) {
      const deepLinkUrl = `${DEEP_LINK_SCHEME}://verify-email?token=${token}`;
      window.location.replace(deepLinkUrl);
    } else {
      (async () => {
        if (!token) return;
        const {data: res} = await verifyEmail.mutateAsync({data: {token}});
        if (res.data?.success) {
          setEmailVerified('true');
        } else {
          setEmailVerified('false');
        }
      })();
    }
  }, [token]);

  if (!isDesktop) {
    return (
      <div className="flex justify-center py-10">
        <p>Install the app to verify email.</p>
      </div>
    );
  }

  return (
    <div className="flex justify-center py-10">
      {(() => {
        if (!token) {
          return (
            <motion.div
              className="bg-white p-10 shadow-lg rounded-xl flex flex-col justify-center items-center"
              initial={{opacity: 0, translateY: 40}}
              animate={{opacity: 1, translateY: 0}}>
              <p className="text-3xl text-red-400 mb-4">Error.</p>
              <p>Failed to verify email.</p>
            </motion.div>
          );
        }

        if (emailVerified === 'pending') {
          return (
            <motion.div
              className="bg-white p-10 shadow-lg rounded-xl flex flex-col justify-center items-center"
              initial={{opacity: 0, translateY: 40}}
              animate={{opacity: 1, translateY: 0}}>
              <p className="text-3xl text-green-500 mb-4">Verifying email...</p>
            </motion.div>
          );
        }

        if (emailVerified === 'false') {
          return (
            <motion.div
              className="bg-white p-10 shadow-lg rounded-xl flex flex-col justify-center items-center"
              initial={{opacity: 0, translateY: 40}}
              animate={{opacity: 1, translateY: 0}}>
              <p className="text-3xl text-red-400 mb-4">Error.</p>
              <p>Failed to verify email.</p>
            </motion.div>
          );
        }

        return (
          <motion.div
            className="bg-white p-10 shadow-lg rounded-xl flex flex-col justify-center items-center"
            initial={{opacity: 0, translateY: 40}}
            animate={{opacity: 1, translateY: 0}}>
            <p className="text-3xl text-green-500 mb-4">Email is verified.</p>
            <p>Please return to the app.</p>
          </motion.div>
        );
      })()}
    </div>
  );
};

export default VerifyEmail;
