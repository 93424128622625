import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {AdminPanel} from 'pages';

import {useGetApiV1Items} from 'api/generated';
import {noImage} from 'assets';
import {useTitle} from 'hooks';
import {BackButton, CardContainer, Img} from 'components';

const FIVE_MINUTES = 300 * 1000;

interface IAdminOrganizationItemsProps {}

const AdminOrganizationItems: React.FC<IAdminOrganizationItemsProps> = () => {
  const {id: organizationId, name: organizationName} = useParams();

  const {data: res} = useGetApiV1Items(
    {organization_id: organizationId as string},
    {query: {staleTime: FIVE_MINUTES}},
  );
  const data = res?.data?.data;

  useTitle(`${organizationName} Items`);

  return (
    <AdminPanel title={organizationName || ''}>
      <BackButton fallbackPath={`/admin/organizations/${organizationId}/${organizationName}`} />

      {data?.map(({id: itemId, image, name, category}) => {
        return (
          <Link
            key={itemId}
            to={`/admin/organizations/${organizationId}/${organizationName}/items/${itemId}`}
            className="w-full transition duration-200 hover:opacity-50">
            <CardContainer className="flex items-center justify-between md:py-4 mb-4">
              <div className="flex items-center">
                <div className="w-20 h-32 flex justify-center items-center mr-4 md:mr-10">
                  <Img src={image ?? noImage} alt={name} className="w-full h-full object-contain" />
                </div>
                <div>
                  <p className="text-lg font-semibold mb-1">{name}</p>
                  <p className="text-md text-gray-500">{category}</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6" />
            </CardContainer>
          </Link>
        );
      })}
    </AdminPanel>
  );
};

export default AdminOrganizationItems;
