import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ChevronLeftIcon} from '@heroicons/react/24/outline';

interface IBackButtonProps {
  fallbackPath?: string;
}

const BackButton: React.FC<IBackButtonProps> = ({fallbackPath = '/admin/dashboard'}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    location.key !== 'default' ? navigate(-1) : navigate(fallbackPath);
  };

  return (
    <button type="button" className="flex items-center mb-6" onClick={handleBack}>
      <ChevronLeftIcon className="w-4 h-4 text-blue-500 mr-1" />
      <p className="text-sm text-blue-600">Back</p>
    </button>
  );
};

export default BackButton;
