import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {AdminPanel} from 'pages';

import {useTitle} from 'hooks';
import {BackButton, CardContainer} from 'components';

interface IAdminOrganizationProps {}

const AdminOrganization: React.FC<IAdminOrganizationProps> = () => {
  const {id, name} = useParams();

  useTitle(name ?? '');

  return (
    <AdminPanel title={name ?? ''}>
      <BackButton fallbackPath="/admin/organizations" />

      <Link
        to={`/admin/organizations/${id}/${name}/users`}
        className="w-full transition duration-200 hover:opacity-50">
        <CardContainer className="flex items-center justify-between md:py-4 mb-4">
          <p className="text-lg font-semibold">Users</p>
          <ChevronRightIcon className="w-6 h-6" />
        </CardContainer>
      </Link>

      <Link
        to={`/admin/organizations/${id}/${name}/items`}
        className="w-full transition duration-200 hover:opacity-50">
        <CardContainer className="flex items-center justify-between md:py-4 mb-4">
          <p className="text-lg font-semibold">Items</p>
          <ChevronRightIcon className="w-6 h-6" />
        </CardContainer>
      </Link>
    </AdminPanel>
  );
};

export default AdminOrganization;
