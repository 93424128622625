import React from 'react';
import {motion} from 'framer-motion';

type ButtonVariantType = 'primary' | 'secondary';
type ButtonStateType = ButtonVariantType | 'disabled' | 'loading';

const BUTTON_STATE_MAP: Record<ButtonStateType, string> = {
  primary: 'bg-[#151110] hover:opacity-75',
  secondary: 'bg-[#f8052b] hover:opacity-75',
  disabled: 'bg-gray-400',
  loading: 'bg-gray-400',
};

interface IButtonProps {
  text: string;
  handlePress?: () => void;
  variant?: ButtonVariantType;
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  text,
  handlePress,
  variant = 'primary',
  disabled = false,
  loading = false,
}) => {
  const state: ButtonStateType = loading
    ? 'loading'
    : disabled
    ? 'disabled'
    : variant === 'primary'
    ? 'primary'
    : 'secondary';

  return (
    <button
      type={handlePress ? 'button' : 'submit'}
      onClick={() => handlePress?.()}
      disabled={loading || disabled}
      className={`flex justify-center items-center w-full rounded-md px-10 py-2 transition-all duration-200 ${BUTTON_STATE_MAP[state]}`}>
      <p className={`text-white font-bold text-md ${loading ? 'opacity-0' : ''}`}>{text}</p>
      {loading && (
        <motion.div
          className="absolute flex justify-center items-center w-5 h-5 bg-gray-300 rounded-full"
          animate={{rotate: 360}}
          transition={{repeat: Infinity, duration: 1, ease: 'linear'}}>
          <div className={`w-[80%] h-[80%] rounded-full ${BUTTON_STATE_MAP['loading']}`}>
            <div className={`w-[25%] h-[25%] rounded-md ${BUTTON_STATE_MAP['loading']}`}></div>
          </div>
        </motion.div>
      )}
    </button>
  );
};

export default Button;
