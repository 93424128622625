import React, {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {AdminPanel} from 'pages';

import {useGetApiV1Users, UserOrganizationTypeState} from 'api/generated';
import {useTitle} from 'hooks';
import {BackButton, Table, TableRowData, TagType} from 'components';

const TAG_STATE_MAP: Record<UserOrganizationTypeState, TagType> = {
  pending: 'warning',
  active: 'success',
};

interface IAdminOrganizationUsersProps {}

const AdminOrganizationUsers: React.FC<IAdminOrganizationUsersProps> = () => {
  const {id: organizationId} = useParams();

  const navigate = useNavigate();
  const {data: res} = useGetApiV1Users({organization_id: organizationId as string});
  const data = res?.data?.data;

  const organizationName = data?.name;
  const userOrganizations = data?.userOrganizations || [];

  useTitle(`${organizationName} Users`);

  const columnTitles = ['Email', 'Status', 'Role', 'Organization role'];
  const rowData: TableRowData[] = userOrganizations.map(uo => {
    return {
      key: uo.id,
      id: uo.user?.id,
      data: [
        {type: 'text', text: uo.userEmail},
        {type: 'tag', text: uo.state, tagType: TAG_STATE_MAP[uo.state]},
        {type: 'tag', text: uo.user?.role},
        {type: 'tag', text: uo.role},
      ],
    };
  });

  const handleClickRow = useCallback(
    (id: string) => {
      navigate(`/admin/users/${id}`);
    },
    [navigate],
  );

  return (
    <AdminPanel title={organizationName || ''}>
      <BackButton fallbackPath={`/admin/organizations/${organizationId}/${organizationName}`} />
      <Table columnTitles={columnTitles} rowData={rowData} handleClickRow={handleClickRow} />
    </AdminPanel>
  );
};

export default AdminOrganizationUsers;
