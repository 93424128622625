import React from 'react';

interface INotFoundProps {}

const NotFound: React.FC<INotFoundProps> = () => {
  return (
    <main className="h-screen bg-[#151110] flex justify-center items-center pb-40 px-3">
      <div className="flex flex-col items-center">
        <p className="text-center text-white text-[140px] md:text-[152px] font-bold -mb-6">404</p>
        <p className="text-center text-white text-xl md:text-2xl font-bold mb-3">
          Oops! That page doesn't exist.
        </p>
        <p className="text-center text-white">
          The page you are looking for doesn't exist or has been moved.
        </p>
      </div>
    </main>
  );
};

export default NotFound;
