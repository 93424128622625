import React from 'react';
import {NotFound} from 'components';

interface IHomeProps {}

const Home: React.FC<IHomeProps> = () => {
  return <NotFound />;
};

export default Home;
