import React from 'react';
import {format} from 'date-fns';
import {AdminPanel} from 'pages';
import {useTitle} from 'hooks';
import {useParams} from 'react-router-dom';
import {useGetApiV1AdminDecisionHistoriesId} from 'api/generated';
import {noImage} from 'assets';
import {BackButton, CardContainer, Img} from 'components';

interface IAdminRequestProps {}

const AdminRequest: React.FC<IAdminRequestProps> = () => {
  useTitle('Request');

  const {id} = useParams();
  const {data: res} = useGetApiV1AdminDecisionHistoriesId(id as string);
  const data = res?.data?.data;

  return (
    <AdminPanel title="Request">
      <BackButton fallbackPath="/admin/requests" />
      {!data
        ? null
        : (() => {
            const {
              customerName,
              contactName,
              phone,
              address,
              completedAt,
              details,
              user: {email},
              items,
              images,
            } = data;

            const list = [
              {key: 'Requester', value: email},
              {
                key: 'Date',
                value: format(new Date(completedAt as string), 'do MMM yyyy, hh:mm aaaa'),
              },
              {key: 'Client', value: customerName},
              ...(contactName ? [{key: 'Contact', value: contactName}] : []),
              ...(phone ? [{key: 'Phone', value: phone}] : []),
              ...(address ? [{key: 'Address', value: address}] : []),
            ];

            return (
              <div>
                <CardContainer className="py-3 md:py-3 mb-4">
                  {list.map(({key, value}) => {
                    return (
                      <div key={key} className="py-1.5 grid grid-cols-1 md:grid-cols-3 gap-x-4">
                        <p className="col-span-1">{key}:</p>
                        <p className="col-span-2 text-gray-600 break-all">{value}</p>
                      </div>
                    );
                  })}
                </CardContainer>
                <CardContainer className="py-6 md:py-8 mb-4">
                  <p className="font-semibold mb-4">Equipment recommendations: {items.length}</p>
                  <div className="flex items-center whitespace-nowrap overflow-auto pb-3">
                    {items.map(({id, name, image, category}) => {
                      return (
                        <div
                          key={id}
                          className="bg-gray-100 rounded p-4 md:p-6 mx-1.5 md:mx-2.5 first:ml-0 last:mr-0 flex-shrink-0">
                          <div className="mb-6">
                            <p className="text-center font-bold text-sm md:text-base">{name}</p>
                            <p className="text-center text-xs md:text-base">{category}</p>
                          </div>
                          <div className="bg-white rounded h-[300px] md:h-[600px] flex justify-center items-center">
                            <Img src={image ?? noImage} alt={name} className="h-full" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </CardContainer>
                <CardContainer className="py-6 md:py-8 mb-4">
                  <p className="font-semibold mb-4">Screenshots: {images.length}</p>
                  <div className="flex items-center whitespace-nowrap overflow-auto pb-3">
                    {images.map((image, index) => {
                      return (
                        <div
                          key={image}
                          className="bg-gray-100 rounded p-4 md:p-6 mx-1.5 md:mx-2.5 first:ml-0 last:mr-0 flex-shrink-0">
                          <div className="bg-white rounded h-[300px] md:h-[600px] flex justify-center items-center">
                            <Img src={image} alt={index.toString()} className="h-full" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </CardContainer>
                <CardContainer className="py-6 md:py-8 mb-4">
                  <p className="font-semibold mb-4">Decisions:</p>
                  {details.map(({id, question, answer}) => {
                    return (
                      <div key={id} className="mb-4">
                        <p className="mb-1">Q: {question}</p>
                        <p className="text-gray-500">A: {answer}</p>
                      </div>
                    );
                  })}
                </CardContainer>
              </div>
            );
          })()}
    </AdminPanel>
  );
};

export default AdminRequest;
