import React, {useEffect} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {SessionBody, usePostApiV1AdminSession} from 'api/generated';
import {useTitle} from 'hooks';
import {extractError} from 'utils';
import {usePersistentStore} from 'stores';
import {Brand, Button, CardContainer, InputText} from 'components';

const schema: yup.Schema<SessionBody> = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Email cannot be blank'),
  password: yup.string().required('Password cannot be blank'),
});

interface IAdminSignInProps {}

const AdminSignIn: React.FC<IAdminSignInProps> = () => {
  useTitle('Sign in');

  const navigate = useNavigate();
  const authToken = usePersistentStore(s => s.authToken);
  const urlAfterAuth = usePersistentStore(s => s.urlAfterAuth);
  const setAuthToken = usePersistentStore(s => s.setAuthToken);
  const setUrlAfterAuth = usePersistentStore(s => s.setUrlAfterAuth);
  const signIn = usePostApiV1AdminSession();

  const {
    control,
    handleSubmit: formSubmit,
    formState: {errors, isValid},
  } = useForm<SessionBody>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleSubmit = async (data: SessionBody) => {
    const {data: res} = await signIn.mutateAsync({
      data: {email: data.email, password: data.password},
    });
    if (res.data) {
      setAuthToken(res.data.jwt);
      if (urlAfterAuth && urlAfterAuth !== '/admin/account') {
        setUrlAfterAuth('');
        navigate(urlAfterAuth);
      } else {
        navigate('/admin/dashboard');
      }
    } else if (res.errors) {
      toast.error(extractError(res.errors));
    }
  };

  useEffect(() => {
    if (authToken) navigate('/admin/dashboard');
  }, [authToken, navigate]);

  if (authToken) return null;

  return (
    <main className="bg-gray-100 min-h-screen">
      <div className="bg-white py-6">
        <div className="flex flex-col max-w-6xl mx-auto px-3">
          <Brand />
          <p className="text-lg md:text-2xl text-center font-semibold pt-14 pb-4">
            Sign in to admin dashboard
          </p>
        </div>
      </div>

      <form
        onSubmit={formSubmit(handleSubmit)}
        className="max-w-6xl mx-auto flex flex-col justify-center items-center py-10 px-3">
        <CardContainer className="max-w-lg w-full flex flex-col items-center">
          <div className="flex flex-col items-center w-full mb-4">
            <Controller
              control={control}
              name="email"
              render={({field: {value, onChange}}) => {
                return (
                  <InputText
                    label="Email"
                    placeholder="your.email@company.com"
                    type="email"
                    value={value}
                    onChange={onChange}
                    error={errors.email?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="password"
              render={({field: {value, onChange}}) => {
                return (
                  <InputText
                    label="Password"
                    placeholder="Password"
                    type="password"
                    value={value}
                    onChange={onChange}
                    error={errors.password?.message}
                  />
                );
              }}
            />
          </div>

          <Button text="Sign in" disabled={!isValid} loading={signIn.isLoading} />
        </CardContainer>
      </form>
    </main>
  );
};

export default AdminSignIn;
