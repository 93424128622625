import React from 'react';
import {pepsico} from 'assets';
import {Img} from 'components';

interface IBrandProps {
  type?: 'light' | 'dark';
}

const Brand: React.FC<IBrandProps> = ({type = 'dark'}) => {
  const textColor = type === 'light' ? 'text-white' : 'text-gray-800';
  return (
    <div className="flex flex-row items-center">
      <Img src={pepsico} alt="pepsi_logo" className="w-8 h-8 mr-1.5" animate={false} />
      <p className={`text-sm md:text-md font-semibold ${textColor}`}>Equipment Selection</p>
    </div>
  );
};

export default Brand;
