import React from 'react';
import {motion} from 'framer-motion';
import {ItemType} from 'models';
import {noImage} from 'assets';
import {Button, Img} from 'components';

interface IItemsProps {
  data: ItemType[];
  handlePress: () => void;
}

const Items: React.FC<IItemsProps> = ({data, handlePress}) => {
  return (
    <motion.div
      className="bg-white py-10 px-3 w-full max-w-xs sm:max-w-sm rounded-md"
      transition={{type: 'spring', damping: 80, stiffness: 1000}}
      initial={{x: '100%', opacity: 0}}
      animate={{x: 0, opacity: 1}}>
      <div className="flex flex-col justify-center items-center">
        <div className="mb-2 flex flex-col justify-center items-center">
          {data.map(({id, name, image, category}) => {
            return (
              <div key={id} className="flex flex-col justify-center items-center mb-6">
                <div className="h-24 mb-3 flex justify-center items-center">
                  <div className="absolute w-24 h-24 rounded-md flex justify-center items-center">
                    <motion.div
                      className="flex justify-center items-center w-5 h-5 rounded-full bg-gray-400"
                      animate={{rotate: 360}}
                      transition={{repeat: Infinity, duration: 1, ease: 'linear'}}>
                      <div className="w-[80%] h-[80%] rounded-full bg-white">
                        <div className="w-[25%] h-[25%] rounded-md bg-white"></div>
                      </div>
                    </motion.div>
                  </div>
                  <Img src={image ?? noImage} className="relative h-full object-cover" alt={name} />
                </div>
                <p className="text-md font-medium">Name: {name}</p>
                <p className="text-sm">Category: {category}</p>
              </div>
            );
          })}
        </div>
        <Button text="Return" handlePress={handlePress} />
      </div>
    </motion.div>
  );
};

export default Items;
